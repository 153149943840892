








import { Component, Vue } from 'vue-property-decorator';
import headerLayout from './header_layout.vue';
import footerLayout from './footer_layout.vue';
@Component({
  name: 'AppMain',
  components:{ headerLayout,footerLayout },
})
export default class extends Vue {
}
